import * as React from 'react';
import { Seo } from '../../components/seo';
import { Link } from 'gatsby';
import { Layout } from '../../layout';
import { Form } from '../../components/form';

import ContactImg from '../../assets/img/bg-contact.jpeg';

const ContactPage = () => {
  return (
    <Layout>
      <Seo
        title="お問い合わせ"
        description="ご返信までにお時間がかかる場合もございますので、お急ぎの方はお電話にてお問い合わせ下さい。"
      />
      {/* <!-- ヘッダータイトル --> */}
      <section className="mb-6">
        <div
          style={{ backgroundImage: `url(${ContactImg})` }}
          className=" relative flex items-center justify-center 2xl:-mt-2 w-screen h-40vh bg-cover bg-center overflow-hidden md:h-60vh
          "
        >
          <h1 className="flex flex-col items-center justify-center tracking-widest">
            <span className="text-white text-2xl md:text-4xl">CONTACT</span>
            <span className="mt-2 text-white text-xs md:text-base">
              お問い合わせ
            </span>
          </h1>
          <div>
            <svg
              className=" wave absolute bottom-0 inset-x-0 -mb-1 w-200vw text-white fill-current"
              viewBox="0 0 1440 64"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M360 19.483C240 -6.49433 120 -6.49433 0 19.483V63.5H1440V19.483C1320 45.4603 1200 45.4603 1080 19.483C960 -6.49433 840 -6.49433 720 19.483C600 45.4603 480 45.4603 360 19.483Z" />
            </svg>
          </div>
        </div>
      </section>
      {/* <!-- 現在ページ --> */}
      <section className="mb-6 mx-auto px-6 md:px-10 lg:max-w-5xl lg:max-w-5xl">
        <div className="flex items-center justify-end">
          <div className="flex items-center justify-center">
            <span className="mr-2 text-primary-blue text-xs md:text-sm">
              <Link to="/">TOP</Link>
            </span>
            <span className="mr-2 text-xs md:text-sm">/</span>
            <span className="text-xs md:text-sm">お問い合わせ</span>
          </div>
        </div>
      </section>
      <section className="mb-12 mx-auto px-6 md:mb-24 md:px-10 lg:mb-32 lg:max-w-4xl">
        <div className="flex flex-col items-center justify-center mb-12 lg:mb-20">
          <div className="mb-2 md:mb-4">
            <h2 className="text-primary-blue md:text-xl">
              お問い合わせフォーム
            </h2>
          </div>
          <div className="w-8 h-0.5 bg-primary-blue md:w-12"></div>
          <div className="mt-4 md:mt-8">
            <p
              className="
                text-center text-xs
                tracking-wider
                leading-5
                md:text-sm
                md:leading-6
              "
            >
              ご返信までにお時間がかかる場合もございますので、お急ぎの方はお電話にてお問い合わせ下さい。
            </p>
          </div>
        </div>
        <Form />
      </section>
    </Layout>
  );
};

export default ContactPage;
