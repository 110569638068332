import React, { useState } from 'react';
import SelectArrow from '../assets/svg/select-arrow.svg';
import { Link, navigate } from 'gatsby';

export const Form = () => {
  const [values, setValues] = useState({
    category: '',
    company: '',
    department: '',
    nameKanji: '',
    nameKana: '',
    postalAddress: '',
    streetAddress: '',
    tel: '',
    email: '',
    confirmEmail: '',
    content: '',
  });
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('/api/form', {
        method: 'POST',
        body: JSON.stringify({ values }),
      });
      console.log('response.status', response.status);
      if (response.status === 200) {
        console.log('entered');
        navigate('/contact/success');
      } else {
        setError('入力欄が正しくありません');
      }
    } catch (err) {
      console.log('err: ', err);
    }
  };
  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="mb-12 mx-auto max-w-2xl lg:mb-20"
      >
        <ul>
          <li className="mb-6">
            <div className="block mb-2 text-sm font-bold lg:mb-4 lg:text-base">
              お問合せ種別
            </div>
            <select
              className="block px-4 py-3 w-full font-sans bg-gray-100 rounded"
              name="category"
              id="select"
              style={{ backgroundImage: `url(${SelectArrow})` }}
              onChange={(e) =>
                setValues({ ...values, category: e.target.value })
              }
            >
              <option disabled selected value="">
                選択してください
              </option>
              <option value="お問い合わせ">お問い合わせ</option>
              <option value="採用について">採用について</option>
            </select>
          </li>
          <li className="mb-6">
            <div className="block mb-2 text-sm font-bold lg:mb-4 lg:text-base">
              会社名
            </div>
            <input
              className="block px-4 py-3 w-full font-sans bg-gray-100 rounded"
              type="text"
              name="company"
              id="company"
              onChange={(e) =>
                setValues({ ...values, company: e.target.value })
              }
              value={values.company}
              placeholder="株式会社◯◯◯◯"
            />
          </li>
          <li className="mb-6">
            <div className="block mb-2 text-sm font-bold lg:mb-4 lg:text-base">
              部署名
            </div>
            <input
              className="block px-4 py-3 w-full font-sans bg-gray-100 rounded"
              type="text"
              name="department"
              value={values.department}
              onChange={(e) =>
                setValues({ ...values, department: e.target.value })
              }
              id="department"
              placeholder="◯◯部"
            />
          </li>
          <li className="mb-6">
            <div className="block mb-2 text-sm font-bold lg:mb-4 lg:text-base">
              お名前
            </div>
            <input
              className="block px-4 py-3 w-full bg-gray-100 rounded"
              type="text"
              name="nameKanji"
              id="nameKanji"
              value={values.nameKanji}
              onChange={(e) =>
                setValues({ ...values, nameKanji: e.target.value })
              }
              placeholder="山田　花子"
              required
            />
          </li>
          <li className="mb-6">
            <div className="block mb-2 text-sm font-bold lg:mb-4 lg:text-base">
              フリガナ
            </div>
            <input
              className="block px-4 py-3 w-full bg-gray-100 rounded"
              type="text"
              name="nameKana"
              id="nameKana"
              value={values.nameKana}
              onChange={(e) =>
                setValues({ ...values, nameKana: e.target.value })
              }
              placeholder="ヤマダ　ハナコ"
              required
            />
          </li>
          <li className="mb-6">
            <div className="block mb-2 text-sm font-bold lg:mb-4 lg:text-base">
              郵便番号
            </div>
            <input
              className="
                  block
                  px-6
                  py-3
                  w-full
                  tracking-wider
                  bg-gray-100
                  rounded
                "
              type="number"
              name="postalAddress"
              id="postalAddress"
              value={values.postalAddress}
              onChange={(e) =>
                setValues({ ...values, postalAddress: e.target.value })
              }
              placeholder="123-4567"
              required
            />
          </li>
          <li className="mb-6">
            <div className="block mb-2 text-sm font-bold lg:mb-4 lg:text-base">
              住所
            </div>
            <input
              className="block px-4 py-3 w-full bg-gray-100 rounded"
              type="text"
              name="streetAddress"
              id="streetAddress"
              value={values.streetAddress}
              onChange={(e) =>
                setValues({ ...values, streetAddress: e.target.value })
              }
              placeholder="八尾市北木ノ本3-36"
              required
            />
          </li>
          <li className="mb-6">
            <div className="block mb-2 text-sm font-bold lg:mb-4 lg:text-base">
              電話番号
            </div>
            <input
              className="
                  block
                  px-6
                  py-3
                  w-full
                  tracking-wider
                  bg-gray-100
                  rounded
                "
              type="tel"
              name="tel"
              id="tel"
              value={values.tel}
              onChange={(e) => setValues({ ...values, tel: e.target.value })}
              placeholder="072-993-8253"
              required
            />
          </li>
          <li className="mb-6">
            <div className="block mb-2 text-sm font-bold lg:mb-4 lg:text-base">
              メールアドレス
            </div>
            <input
              className="block px-4 py-3 w-full font-sans bg-gray-100 rounded"
              type="email"
              name="email"
              id="email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              placeholder="info@example.com"
              required
            />
          </li>
          <li className="mb-6">
            <div className="block mb-2 text-sm font-bold lg:mb-4 lg:text-base">
              メールアドレス（確認）
            </div>
            <input
              className="block px-4 py-3 w-full font-sans bg-gray-100 rounded"
              type="email"
              name="confirmEmail"
              id="confirmEmail"
              placeholder="info@example.com"
              value={values.confirmEmail}
              onChange={(e) =>
                setValues({ ...values, confirmEmail: e.target.value })
              }
              required
            />
          </li>
          <li>
            <div className="block mb-2 text-sm font-bold lg:mb-4 lg:text-base">
              お問い合わせ内容
            </div>
            <textarea
              className="block px-4 py-3 w-full font-sans bg-gray-100 rounded"
              name="content"
              rows="8"
              maxLength="4000"
              placeholder="最大4000文字まで"
              id="content"
              value={values.content}
              onChange={(e) =>
                setValues({ ...values, content: e.target.value })
              }
              required
            ></textarea>
          </li>
        </ul>
        <div className="my-4 md:my-8">
          <p className="text-center text-sm font-semibold lg:text-base">
            お問い合わせには、
            <Link to="/privacy" className="text-primary-blue">
              プライバシーポリシー
            </Link>
            への同意が必要です。
          </p>
        </div>

        {error ? (
          <div className="px-3 py-3">
            <h4 className="text-red-500">{error}</h4>
          </div>
        ) : null}

        <div className="relative mx-auto w-fit-content">
          <button
            type="submit"
            className=" btn-background-slide group flex items-center justify-center mx-auto py-2 w-48 bg-primary-blue md:py-4 md:w-64
            "
          >
            <span
              className=" z-10 group-hover:text-primary-blue text-white text-sm tracking-wider transition duration-300 ease-in-out md:text-base
              "
            >
              入力内容を確認する
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};
